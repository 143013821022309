import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/Wallet',
    name: 'Wallet',
    component: () => import('../views/wallet/Wallet.vue')
  },
  {
    path: '/Team',
    name: 'Team',
    component: () => import('../views/user/Team.vue')
  },
  {
    path: '/Chongzhi',
    name: 'Chongzhi',
    component: () => import('../views/wallet/Chongzhi.vue')
  },
  {
    path: '/Chongzhi2',
    name: 'Chongzhi2',
    component: () => import('../views/wallet/Chongzhi2.vue')
  },
  {
    path: '/Tixian',
    name: 'Tixian',
    component: () => import('../views/wallet/Tixian.vue')
  },
  {
    path: '/Zhuanhuan',
    name: 'Zhuanhuan',
    component: () => import('../views/wallet/Zhuanhuan.vue')
  },
  {
    path: '/Zhuanzhang',
    name: 'Zhuanzhang',
    component: () => import('../views/wallet/Zhuanzhang.vue')
  },
  {
    path: '/ZhuanzhangRecord',
    name: 'ZhuanzhangRecord',
    component: () => import('../views/wallet/ZhuanzhangRecord.vue')
  },
  {
    path: '/ZhuanhuanRecord',
    name: 'ZhuanhuanRecord',
    component: () => import('../views/wallet/ZhuanhuanRecord.vue')
  },
  {
    path: '/Share',
    name: 'Share',
    component: () => import('../views/user/Share.vue')
  },
  {
    path: '/Bill',
    name: 'Bill',
    component: () => import('../views/wallet/Bill.vue')
  },
  {
    path: '/Bonus',
    name: 'Bonus',
    component: () => import('../views/wallet/Bonus.vue')
  },
  {
    path: '/News',
    name: 'News',
    component: () => import('../views/news/News.vue')
  },
  {
    path: '/NewsDetails',
    name: 'NewsDetails',
    component: () => import('../views/news/NewsDetails.vue')
  },
  {
    path: '/TixianRecord',
    name: 'TixianRecord',
    component: () => import('../views/wallet/TixianRecord.vue')
  },
  {
    path: '/Market',
    name: 'Market',
    component: () => import('../views/market/index.vue')
  },
  {
    path: '/Pools',
    name: 'Pools',
    component: () => import('../views/pools/index.vue')
  },
  {
    path: '/PoolsRecord',
    name: 'PoolsRecord',
    component: () => import('../views/pools/PoolsRecord.vue')
  },
  {
    path: '/User',
    name: 'User',
    component: () => import('../views/user/index.vue')
  },
  {
    path: '/About',
    name: 'About',
    component: () => import('../views/other/About.vue')
  },
  {
    path: '/fwzx',
    name: 'fwzx',
    component: () => import('../views/user/fwzx.vue')
  },
  {
    path: '/financial',
    name: 'financial',
    component: () => import('../views/financial/index.vue')
  },
  {
    path: '/financialRecord',
    name: 'financialRecord',
    component: () => import('../views/financial/financialRecord.vue')
  },
  {
    path: '/InputOrder',
    name: 'InputOrder',
    component: () => import('../views/inputOrder/index.vue')
  },
  {
    path: '/InputOrderRecord',
    name: 'InputOrderRecord',
    component: () => import('../views/inputOrder/InputOrderRecord.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, form ,next) => {
  document.body.scrollTop = 0;
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  next()
})

export default router
