module.exports = {
  '竞猜下注统计到指定用户':'競猜下注統計到指定用戶',
  '哈希竞猜退款':'哈希競猜退款',
  '第':'第',
  '期':'期',
  '未中奖':'未中獎',
  '已中奖':'已中獎',
  '已撤单':'已撤單',
  '撤单申请已提交':'撤單申請已提交',
  '投资已撤单或正在撤单':'投資已撤單或正在撤單',
  '认购成功':'認購成功',
  '复购成功':'複購成功',
  '不在下注范围':'不在下注範圍',
  '当前无法参与竞猜,请联系管理员':'當前無法參與競猜，請聯系管理員',
  '请重新输入':'請重新輸入',
  '铸币数量太少':'鑄幣數量太少',
  '不足':'不足',
  '之间的金额':'之間的金額',
  '不在投资范围':'不在投資範圍',
  '国库共识待发行量':'國庫共識待發行量',
  '强制卖出':'強制賣出',
  '哈希竞猜中奖':'雜湊競猜中獎',
  '用户主动撤单':'用戶主動撤單',
  '投资USDT':'投資USDT',
  '收益':'收益',
  '充值':'充值',
  '提现':'提現',
  '兑换':'兌換',
  '增减':'增减',
  '消费':'消費',
  '退款':'退款',
  '总计':'總計',
  '上拿':'上拿',
  '直推':'直推',
  '新闻公告': '新聞公告',
  '详情': '詳情',
  '邀请': '邀請',
  '存入': '存入',
  '资产': '資產',
  '转账': '轉帳',
  '当前价格': '當前價格',
  '立即': '立即',
  '认购': '認購',
  '复购': '複購',
  '消耗': '消耗',
  '认购记录': '認購記錄',
  '出局倍数': '出局倍數',
  '倍': '倍',
  '释放比例': '釋放比例',
  '数量': '數量',
  '可用': '可用',
  '最大': '最大',
  '排行榜': '排行榜',
  '个人销售': '個人銷售',
  '直推销售': '直推銷售',
  '排名': '排名',
  '地址': '地址',
  '持有': '持有',
  '业绩': '業績',
  '友情链接': '友情連結',
  '暂无记录': '暫無記錄',
  '没有更多了': '沒有更多了',
  '我的地址': '我的地址',
  '级别': '級別',
  '首页': '首頁',
  '国库兑换': '國庫兌換',
  '国库USDT记录': '國庫USDT記錄',
  '国库LBD铸造': '國庫LBD鑄造',
  '哈希竞猜': '雜湊競猜',
  '我的资产': '我的資產',
  '账单记录': '帳單記錄',
  '我的收益': '我的收益',
  '我的社区': '我的社區',
  '邀请好友': '邀請好友',
  '国库': '國庫',
  '国库共识USDT总量': '國庫共識USDT總量',
  '国库共识铸币流通量': '國庫共識鑄幣流通量',
  '累计销毁总量': '累計銷毀總量',
  '铸币流通占比': '鑄幣流通占比',
  'LBD待发行量': 'LBD待發行量',
  'LBD流通总量': 'LBD流通總量',
  'LBD销毁总量': 'LBD銷毀總量',
  '铸币': '鑄幣',
  '铸币记录': '鑄幣記錄',
  '请输入': '請輸入',
  '交易': '交易',
  '币价': '幣價',
  '金额': '金額',
  '实际获得': '實際獲得',
  '回流国库': '回流國庫',
  '卖出': '賣出',
  '国库USDT剩余总量': '國庫USDT剩餘總量',
  '查看详情': '查看詳情',
  '国库LBD剩余总量': '國庫LBD剩餘總量',
  '国库U池余额': '國庫U池餘額',
  '变动明细': '變動明細',
  '价格': '價格',
  '时间': '時間',
  '铸币池余额': '鑄幣池餘額',
  '铸币明细': '鑄幣明細',
  '铸币数量': '鑄幣數量',
  '铸币价格': '鑄幣價格',
  '铸币时间': '鑄幣時間',
  '竞猜记录': '競猜記錄',
  '猜小': '猜小',
  '猜大': '猜大',
  '猜单': '猜單',
  '猜双': '猜雙',
  '竞猜金额': '競猜金額',
  '请输入竞猜金额': '請輸入競猜金額',
  '最近竞猜结果': '最近競猜結果',
  '确定': '確定',
  '取消': '取消',
  '我的竞猜': '我的競猜',
  '竞猜': '競猜',
  '竞猜结果': '競猜結果',
  '获得': '獲得',
  '我的账户': '我的帳戶',
  '币种': '幣種',
  '铸造': '鑄造',
  '团队收益': '團隊收益',
  '推荐收益': '推薦收益',
  '静态收益': '靜態收益',
  '平级收益': '平級收益',
  '奖励明细': '獎勵明細',
  '当前我的等级': '當前我的等級',
  '社区节点': '社區節點',
  '有效节点': '有效節點',
  '社区业绩': '社區業績',
  '大区业绩': '大區業績',
  '小区业绩': '社區業績',
  '我的套餐': '我的套餐',
  '总认购': '總認購',
  '总收益': '總收益',
  '我的直推': '我的直推',
  '序号': '序號',
  '注册时间': '註冊時間',
  '长按保存图片': '長按保存圖片',
  '我的邀请地址': '我的邀請地址',
  '复制地址': '複製地址',
  '复制成功': '複製成功',
  '返回': '返回',
  '等级': '等級',
  '有效人数': '有效人數',
  '团队人数': '團隊人數',
  '存入数量': '存入數量',
  '请输入数量': '請輸入數量',
  '钱包余额': '錢包餘額',
  '全部': '全部',
  '交易广播成功': '交易廣播成功',
  '钱包余额不足': '錢包餘額不足',
  '正在交易中...': '正在交易中…',
  '提取币种': '選取幣種',
  '提取地址': '選取地址',
  '提取数量': '選取數量',
  '账户余额': '帳戶餘額',
  '手续费': '手續費',
  '提交': '提交',
  '转账币种': '轉帳幣種',
  '转账地址': '轉帳地址',
  '请输入地址': '請輸入地址',
  '转账数量': '轉帳數量',
  '静态释放': '靜態釋放',
  '比例': '比例',
  '动态加速释放': '動態加速釋放',
  '出局倍率': '出局倍率',
  '撤单': '撤單',
  '提示': '提示',
  '激活账号': '啟動帳號',
  '请输入邀请码进行授权激活': '請輸入邀請碼進行授權啟動',
  '请输入邀请码': '請輸入邀請碼',
  '注册': '注册',
  '获取地址错误': '獲取地址錯誤',
  '认购中...': '認購中...',
  '复购中...': '複購中...',
  '卖出中...': '賣出中...',
  '铸币中...': '鑄幣中...',
  '竞猜下注中...': '競猜下注中...',
  '加载中...': '加載中...',
  '提现中...': '提現中...',
  '撤单中...': '撤單中...',
  '转换中...': '轉換中...',
  '转账中...': '轉帳中...',
  '确定要认购吗?': '確定要認購嗎？',
  '确定要复购吗?': '確定要複購嗎？',
  '确定要卖出吗?': '確定要賣出嗎？',
  '确定要铸币吗?': '確定要鑄幣嗎？',
  '确定要竞猜吗?': '確定要競猜嗎？',
  '确定要提现吗?': '確定要提現嗎？',
  '确定要转换吗?': '確定要轉換嗎？',
  '确定要转账吗?': '確定要轉帳嗎？',
  '请选择货币': '請選擇貨幣',
  '未回本撤出合约，只返回55%USDT，': '未回本撤出合約，只返回55%USDT，',
  '确定撤出吗?': '確定撤出嗎？',
  '销毁': '銷毀',
  '销毁记录': '銷毀記錄',
  '类型': '類型',
  '未注册': '未注册',
  '账号已被冻结！': '帳號已被凍結！',
  'Token异常，登入失敗': 'Token异常，登入失敗',
  '登录成功': '登入成功',
  '参数错误': '參數錯誤',
  '请勿重复提交': '請勿重複提交',
  '钱包地址已存在': '錢包地址已存在',
  '推荐人不存在': '推薦人不存在',
  '注册成功': '注册成功',
  '注册失败': '注册失敗',
  '注册异常': '注册异常',
  '找不到LBD数据': '找不到LBD數據',
  '获取成功': '獲取成功',
  '获取饼图数据异常': '獲取餅圖數據异常',
  '获取公开铸币信息列表异常': '獲取公開鑄幣信息清單异常',
  '用户信息异常': '用戶資訊异常',
  '钱包信息错误': '錢包資訊錯誤',
  '通证参数不存在': '通證參數不存在',
  '获取兑换相关信息异常': '獲取兌換相關資訊异常',
  '获取K线数据异常': '獲取K線數據异常',
  '获取排行榜数据异常': '獲取排行榜數據异常',
  '查询成功': '査詢成功',
  '查询个人钱包异常': '査詢個人錢包异常',
  '最低100，且100的倍数起投': '最低100，且100的倍數起投',
  '区块签名错误': '區塊簽名錯誤',
  '区块地址错误': '區塊地址錯誤',
  '找不到投资设置': '找不到投資設定',
  '当前无法进行投资,请联系管理员': '當前無法進行投資，請聯系管理員',
  '用户钱包异常': '用戶錢包异常',
  '投资异常': '投資异常',
  '获取投资选项异常': '獲取投資選項异常',
  '文章不存在': '文章不存在',
  '查询文章异常': '査詢文章异常',
  '兑换计算结果异常': '兌換計算結果异常',
  '交易数量错误': '交易數量錯誤',
  '暂时无法进行交易,请联系管理员': '暫時無法進行交易，請聯系管理員',
  '交易数量太少': '交易數量太少',
  '交易成功': '交易成功',
  '交易异常': '交易异常',
  '金额错误': '金額錯誤',
  '铸币成功': '鑄幣成功',
  '铸币异常': '鑄幣异常',
  '查询幻灯片异常': '査詢幻燈片异常',
  '查询公告异常': '査詢公告异常',
  '请选择玩法': '請選擇玩法',
  '竞猜设置异常': '競猜設定异常',
  '当前没有可参与的竞猜': '當前沒有可參與的競猜',
  '当前竞猜已停止下注': '當前競猜已停止下注',
  '下注成功': '下注成功',
  '竞猜下注异常': '競猜下注异常',
  '获取用户竞猜订单列表异常': '獲取用戶競猜訂單清單异常',
  '获取用户竞猜列表异常': '獲取用戶競猜清單异常',
  '查询新闻异常': '査詢新聞异常',
  '新闻不存在': '新聞不存在',
  '查询单条新闻异常': '査詢單條新聞异常',
  '获取我的社区页面数据异常': '獲取我的社區頁面數據异常',
  '获取我的社区列表异常': '獲取我的社區清單异常',
  '查询用户信息异常': '査詢用戶資訊异常',
  '获取账单异常': '獲取帳單异常',
  '查询奖金来源异常': '査詢獎金來源异常',
  '区块链参数异常': '區塊鏈參數异常',
  '查询可转换货币异常': '査詢可轉換貨幣异常',
  '查询可提现货币异常': '査詢可提現貨幣异常',
  '用户获取投资列表异常': '用戶獲取投資清單异常',
  '投资不存在': '投資不存在',
  '投资已出局': '投資已出局',
  '投资已撤单': '投資已撤單',
  '投资状态异常': '投資狀態异常',
  '当前投资无法撤单': '當前投資無法撤單',
  '撤单成功': '撤單成功',
  '撤单异常': '撤單异常',
  '获取我的资产页面数据异常': '獲取我的資產頁面數據异常',
  '转换': '轉換',
  '转换币种': '轉換幣種',
  '转换数量': '轉換數量',
  '确实': '確實',
  '转': '轉',
  '系统繁忙,请稍后再试': '系統繁忙，請稍後再試',
  '货币不存在': '貨幣不存在',
  '转款人钱包错误': '轉款人錢包錯誤',
  '收款人不存在': '收款人不存在',
  '收款人钱包错误': '收款人錢包錯誤',
  '该货币只能转给团队人员': '該貨幣只能轉給團隊人員',
  '转账成功': '轉帳成功',
  '转账异常': '轉帳异常',
  '查询可转账货币异常': '査詢可轉帳貨幣异常',
  '铸币消耗USD': '鑄幣消耗USD',
  '铸币LBD数量': '鑄幣LBD數量',
  '提取': '提取',
  '立即铸币': '立即鑄幣',
  '单双走势': '單雙走勢',
  '大小走势': '大小走勢',
  '单': '單',
  '双': '雙',
  '大': '大',
  '小': '小',
  '待开奖': '待開獎',
  '已开奖': '已開獎',
  '区块高度': '區塊高度',
  '涨幅': '漲幅',
  'USD佣金': 'USD傭金',
  '首页滚动公告': 'LIBERAL DEAL是由幣安生態官方推出的一個去中心化自治組織（DAO組織）旨在建設一個有長遠影響力的國際DAO組織，凝聚高緯認知的夥伴，用社區的影響力，賦能全球頂級區塊鏈生態建設。 Liberal deal（LBD）代幣總發行3100w枚，極至通縮31萬，LBD流通幣量價值通過國庫去中心化協定錨定BNB價格。 累計到5萬地址流量後上線Pancake進行交易，將在打造的LBD生態應用中流通。',
  '玩法规则': '玩法規則',
  '为': '為',
  '玩法规则内容': '幣安區塊雜湊值的最後一比特數位競猜，用USDT下注，若最後一比特是字母即往前推一直找到數位為止，始終以數位0~9為開獎結果，每3分鐘自動開獎一期，每期倒數計時30秒禁止下注，猜中返1.94倍USD。',
  '社区收益': '社區收益',
  '提取记录': '選取記錄',
  '提现金额': '提現金額',
  '交易哈希': '交易雜湊',
  'DG投入':'DG投入',
  'DG投入记录':'DG投入記錄',
  '请输入金额':'請輸入金額'
}