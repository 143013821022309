module.exports = {
  '竞猜下注统计到指定用户':'指定ユーザーへのゲスティングベット統計',
  '哈希竞猜退款':'ハッシュゲスティング返金',
  '第':'第 (だい)',
  '期':'きかん',
  '未中奖':'当選なし',
  '已中奖':'当選',
  '已撤单':'キャンセル済み',
  '撤单申请已提交':'キャンセル申請が提出されました',
  '投资已撤单或正在撤单':'投資の取消済または取消中',
  '认购成功':'購読成功',
  '复购成功':'リピート購入成功',
  '不在下注范围':'ベット範囲外です',
  '当前无法参与竞猜,请联系管理员':'現在クイズに参加できません。管理者に連絡してください',
  '请重新输入':'再入力してください',
  '铸币数量太少':'鋳貨の数が少なすぎる',
  '不足':'不足',
  '之间的金额':'間の金額',
  '不在投资范围':'投資の範囲外',
  '国库共识待发行量':'国庫コンセンサス発行待ち量',
  '强制卖出':'押し売り',
  '哈希竞猜中奖':'ハッシュクイズ当選',
  '用户主动撤单':'ユーザーの能動的なキャンセル',
  '投资USDT':'USDTへの投資',
  '收益':'に利益を与える',
  '充值':'チャージ',
  '提现':'引き出し',
  '兑换':'換算',
  '增减':'増減',
  '消费':'しょうひ',
  '退款':'払い戻し',
  '总计':'合計＃ゴウケイ＃',
  '上拿':'に手を触れる',
  '直推':'直進',
  '间推':'間引き',
  '新闻公告': 'ニュース',
  '详情': '詳細',
  '邀请': 'に勧める',
  '存入': 'よびだし',
  '资产': '資産',
  '转账': '振り替え',
  '当前价格': '現在の価格',
  '立即': 'すぐに',
  '认购': '購読する',
  '复购': '再購入',
  '消耗': 'しょうひ',
  '认购记录': '購入履歴',
  '出局倍数': 'アウト倍数',
  '倍': '倍数',
  '释放比例': 'リリーススケール',
  '数量': '数量',
  '可用': '使用可能',
  '最大': '最大値',
  '排行榜': 'ランキング',
  '个人销售': '個人販売',
  '直推销售': '直進販売',
  '排名': 'ランキング',
  '地址': 'アドレス',
  '持有': '保有',
  '业绩': '業績',
  '友情链接': '相互リンク',
  '暂无记录': '記録はまだありません',
  '没有更多了': 'これ以上ない',
  '我的地址': '私の住所',
  '级别': 'レベル＃レベル＃',
  '首页': 'トップページ',
  '国库兑换': '国庫両替',
  '国库USDT记录': '国庫USDT記録',
  '国库LBD铸造': '国庫LBD鋳造',
  '哈希竞猜': 'ハッシュクイズ',
  '我的资产': 'マイ資産',
  '账单记录': '請求書記録',
  '我的收益': '私の収益',
  '我的社区': 'マイコミュニティ',
  '邀请好友': '友達を招待する',
  '国库': '国庫',
  '国库共识USDT总量': '国庫コンセンサスUSDT総量',
  '国库共识铸币流通量': '国庫コンセンサス鋳貨流通量',
  '累计销毁总量': '廃棄合計の累計',
  '铸币流通占比': '鋳貨流通占有率',
  'LBD待发行量': 'LBD発行待ち量',
  'LBD流通总量': 'LBD流通総量',
  'LBD销毁总量': 'LBD破棄総量',
  '铸币': '貨幣を鋳造する',
  '铸币记录': '鋳貨記録',
  '请输入': '入力してください',
  '交易': '取り引き',
  '币价': '貨幣価格',
  '金额': '金額',
  '实际获得': '実際に取得',
  '回流国库': '国庫に還流する',
  '卖出': '売り出す',
  '国库USDT剩余总量': '国庫USDT余剰総量',
  '查看详情': '詳細の表示',
  '国库LBD剩余总量': '国庫LBD余剰総量',
  '国库U池余额': '国庫Uプール残高',
  '变动明细': '変動の詳細',
  '价格': '価格',
  '时间': '時間',
  '铸币池余额': '鋳貨プール残高',
  '铸币明细': '鋳貨明細',
  '铸币数量': '鋳貨数量',
  '铸币价格': '鋳貨価格',
  '铸币时间': '鋳貨時間',
  '竞猜记录': 'クイズ記録',
  '猜小': '小さいと思う',
  '猜大': 'サイズを当てる',
  '猜单': '当て書き',
  '猜双': 'ペアを当てる',
  '竞猜金额': 'クイズの金額',
  '请输入竞猜金额': 'クイズ金額を入力してください',
  '最近竞猜结果': '最近のクイズ結果',
  '确定': 'を選択して、',
  '取消': 'キャンセル',
  '我的竞猜': '私のクイズ',
  '竞猜': 'クイズ',
  '竞猜结果': 'クイズの結果',
  '获得': '取得＃シュトク＃',
  '我的账户': 'マイアカウント',
  '币种': '通貨＃ツウカ＃',
  '铸造': '鋳造',
  '团队收益': 'チーム収益',
  '推荐收益': '推奨収益',
  '静态收益': 'せいてきえき',
  '平级收益': 'レベル収益',
  '奖励明细': '奨励詳細',
  '当前我的等级': '現在の自分のレベル',
  '社区节点': 'コミュニティノード',
  '有效节点': '有効なノード',
  '社区业绩': 'コミュニティの実績',
  '大区业绩': '大区の業績',
  '小区业绩': '団地の業績',
  '我的套餐': 'マイコース',
  '总认购': '購買依頼',
  '总收益': '総収益',
  '我的直推': '私のストレートプッシュ',
  '序号': 'シーケンス番号',
  '注册时间': '登録時間',
  '长按保存图片': '長押しで画像を保存',
  '我的邀请地址': '私の招待先',
  '复制地址': 'アドレスのコピー',
  '复制成功': 'コピー成功',
  '返回': 'リターンマッチ',
  '等级': '等級',
  '有效人数': '有効人数',
  '团队人数': 'チーム人数',
  '存入数量': '預け入れ数量',
  '请输入数量': '数量を入力してください',
  '钱包余额': 'ウォレット残高',
  '全部': 'すべて',
  '交易广播成功': 'トランザクションブロードキャスト成功',
  '钱包余额不足': '財布の残高が不足している',
  '正在交易中...': '取引中...',
  '提取币种': '通貨の抽出',
  '提取地址': 'アドレスの抽出',
  '提取数量': '抽出数量',
  '账户余额': 'アカウント残高',
  '手续费': '手数料',
  '提交': '送信',
  '转账币种': '振替通貨',
  '转账地址': '振替先住所',
  '请输入地址': '住所を入力してください',
  '转账数量': '振替数量',
  '静态释放': 'せいてきかいほう',
  '比例': 'ひれい',
  '动态加速释放': 'ダイナミックアクセラレータリリーズ',
  '出局倍率': 'アウト倍率',
  '撤单': '請求を取り消す',
  '提示': 'ヒント',
  '激活账号': 'アカウントのアクティブ化',
  '请输入邀请码进行授权激活': '認証アクティベーションに招待コードを入力してください',
  '请输入邀请码': '招待コードを入力してください',
  '注册': '登録',
  '获取地址错误': 'アドレス取得エラー',
  '认购中...': '購入受付中...',
  '复购中...': '再購入中...',
  '卖出中...': '売り出し中...',
  '铸币中...': '鋳貨中...',
  '竞猜下注中...': 'クイズベット...',
  '加载中...': 'ロード中...',
  '提现中...': '現金化中...',
  '撤单中...': 'キャンセル中...',
  '转换中...': '変換中...',
  '转账中...': '振り替え中...',
  '确定要认购吗?': '本当に購入しますか？',
  '确定要复购吗?': '本当に再購入しますか？',
  '确定要卖出吗?': '本当に売りますか。',
  '确定要铸币吗?': '貨幣を鋳造しますか？',
  '确定要竞猜吗?': '本当にクイズしますか？',
  '确定要提现吗?': '本当に現金を引き出しますか。',
  '确定要转换吗?': '変換しますか？',
  '确定要转账吗?': '本当に振り替えますか。',
  '请选择货币': '通貨を選択してください',
  '未回本撤出合约，只返回55%USDT，': '本撤退契約に戻らず、55%USDTだけが戻ってきた。',
  '确定撤出吗?': '撤退は確実ですか。',
  '销毁': '破棄',
  '销毁记录': 'レコードの破棄',
  '类型': 'を選択してオプションを設定します。',
  '未注册': '未登録',
  '账号已被冻结！': 'アカウントが凍結されました！',
  'Token异常，登入失敗': 'トークン異常、ログイン失敗',
  '登录成功': 'ログイン成功',
  '参数错误': 'パラメータエラー',
  '请勿重复提交': '繰り返し送信しないでください',
  '钱包地址已存在': 'ウォレットアドレスはすでに存在します',
  '推荐人不存在': '推薦人は存在しません',
  '注册成功': '登録に成功しました',
  '注册失败': '登録に失敗しました',
  '注册异常': '例外の登録',
  '找不到LBD数据': 'LBDデータが見つかりません',
  '获取成功': '取得に成功しました',
  '获取饼图数据异常': '円グラフデータ異常の取得',
  '获取公开铸币信息列表异常': '公開鋳貨情報リスト異常の取得',
  '用户信息异常': 'ユーザ情報異常',
  '钱包信息错误': 'ウォレット情報エラー',
  '通证参数不存在': '通証パラメータが存在しません',
  '获取兑换相关信息异常': '換算関連情報の取得例外',
  '获取K线数据异常': 'Kラインデータ例外の取得',
  '获取排行榜数据异常': '取得ランキングデータ異常',
  '查询成功': 'クエリに成功しました',
  '查询个人钱包异常': '個人用財布の異常の照会',
  '最低100，且100的倍数起投': '最低100、かつ100の倍数からの投げ',
  '区块签名错误': 'ブロック署名エラー',
  '区块地址错误': 'ブロックアドレスエラー',
  '找不到投资设置': '投資設定が見つかりません',
  '当前无法进行投资,请联系管理员': '現在投資できません。管理者に連絡してください',
  '用户钱包异常': 'ユーザーウォレット例外',
  '投资异常': '投資異常',
  '获取投资选项异常': '投資オプション例外の取得',
  '文章不存在': '文章が存在しません',
  '查询文章异常': 'クエリ記事の例外',
  '兑换计算结果异常': '換算計算結果の例外',
  '交易数量错误': '取引数量エラー',
  '暂时无法进行交易,请联系管理员': 'しばらく取引できません。管理者に連絡してください',
  '交易数量太少': '取引数量が少なすぎる',
  '交易成功': '取引成功',
  '交易异常': '取引異常',
  '金额错误': '金額エラー',
  '铸币成功': '貨幣鋳造に成功した',
  '铸币异常': '鋳貨異常',
  '查询幻灯片异常': 'クエリースライドの例外',
  '查询公告异常': '照会公告異常',
  '请选择玩法': '遊び方を選んでください',
  '竞猜设置异常': 'クイズ設定異常',
  '当前没有可参与的竞猜': '現在参加できるクイズはありません',
  '当前竞猜已停止下注': '現在のクイズはベットを停止しています',
  '下注成功': 'ベット成功',
  '竞猜下注异常': 'クイズベット異常',
  '获取用户竞猜订单列表异常': 'ユーザーのクイズ受注リスト例外の取得',
  '获取用户竞猜列表异常': 'ユーザー・クイズ・リストの例外の取得',
  '查询新闻异常': 'クエリ・ニュースの例外',
  '新闻不存在': 'ニュースは存在しません',
  '查询单条新闻异常': '単一のニュース例外のクエリ',
  '获取我的社区页面数据异常': 'マイコミュニティページデータ例外の取得',
  '获取我的社区列表异常': 'マイコミュニティリスト例外の取得',
  '查询用户信息异常': 'クエリ・ユーザー情報の例外',
  '获取账单异常': '請求書例外の取得',
  '查询奖金来源异常': 'ボーナスソース例外の照会',
  '区块链参数异常': 'ブロックチェーンパラメータ異常',
  '查询可转换货币异常': '変換可能通貨例外の問合せ',
  '查询可提现货币异常': 'クエリ現金化可能通貨の例外',
  '用户获取投资列表异常': 'ユーザー取得投資リスト異常',
  '投资不存在': '投資は存在しません',
  '投资已出局': '投資アウト',
  '投资已撤单': '投資取消済',
  '投资状态异常': '投資状態異常',
  '当前投资无法撤单': '現在の投資は解約できません',
  '撤单成功': 'キャンセル成功',
  '撤单异常': 'キャンセル例外',
  '获取我的资产页面数据异常': 'マイアセットページデータ例外の取得',
  '转换': 'へんかん',
  '转换币种': '換算通貨',
  '转换数量': '変換数',
  '确实': '確かに',
  '转': '回転',
  '系统繁忙,请稍后再试': 'システムがビジーなので、後で再試行してください',
  '货币不存在': '通貨は存在しません',
  '转款人钱包错误': '振込人財布エラー',
  '收款人不存在': '受取人は存在しません',
  '收款人钱包错误': '受取人財布エラー',
  '该货币只能转给团队人员': 'この通貨はチームメンバーにのみ渡すことができます',
  '转账成功': '振り替え成功',
  '转账异常': '振替例外',
  '查询可转账货币异常': '振替可能通貨例外の問合せ',
  '铸币消耗USD': '鋳貨消費USD',
  '铸币LBD数量': '鋳貨LBD数量',
  '提取': 'ちゅうしゅつ',
  '立即铸币': 'ただちに貨幣を鋳造する',
  '单双走势': 'シングルペアポテンシャル',
  '大小走势': '大小の動き',
  '单': 'シン',
  '双': 'ダブ',
  '大': '大き',
  '小': '小さ',
  '待开奖': '描画される',
  '已开奖': '受賞済み',
  '区块高度': 'ブロックの高さ',
  '涨幅': '上昇幅',
  'USD佣金': 'USDコミッション',
  '首页滚动公告': 'LIBERAL DEALは幣安生態が公式に打ち出した脱中心化自治組織（DAO組織）の目的は長期的な影響力を持つ国際DAO組織を建設し、高緯度認知のパートナーを凝集し、コミュニティの影響力を用いて、世界トップクラスのブロックチェーン生態建設を実現することである。Liberal deal（LBD）トークンの総発行枚数は3100 w枚で、デフレ31万まで達し、LBD流通量価値は国庫脱中心化プロトコルを通じてBNB価格をアンカーした。5万アドレスのトラフィックを累計した後、Pancakeをオンラインで取引し、構築されたLBDエコアプリケーションに流通する。',
  '玩法规则': '遊び方のルール',
  '为': 'のために为',
  '玩法规则内容': '貨幣安ブロックのハッシュ値の最後の数字のクイズは、USDTで賭けて、もし最後の1位がアルファベットであるならば前に押してずっと数字を見つけるまで、ずっと数字の0 ~ 9を賞金の結果にして、3分ごとに自動的に賞金を出して、毎期のカウントダウン30秒はベットを禁止して、的中は1.94倍のUSDを返します。',
  '社区收益': 'コミュニティの収益',
  '提取记录': 'レコードの抽出',
  '提现金额': '引き出し金額',
  '交易哈希': '取引ハッシュ',
  'DG投入':'DG投入',
  'DG投入记录':'DG投入記録',
  '请输入金额':'金額を入力してください'
}