module.exports = {
  '竞猜下注统计到指定用户':'สถิติการเดิมพันการเดาในการเดิมพันถึงผู้ใช้ที่กำหนด',
  '哈希竞猜退款':'การคืนเงินการเดาแฮช',
  '第':'ที่',
  '期':'ระยะเวลา',
  '未中奖':'ไม่ถูกรางวัล',
  '已中奖':'ถูกรางวัลแล้ว',
  '已撤单':'ถูกยกเลิกแล้ว',
  '撤单申请已提交':'คำขอยกเลิกได้ถูกส่งแล้ว',
  '投资已撤单或正在撤单':'การถอนเงินลงทุน หรือ อยู่ระหว่างการถอนเงิน',
  '认购成功':'สมัครสมาชิกเรียบร้อยแล้ว',
  '复购成功':'ซื้อสินค้าเดิมเรียบร้อยแล้ว',
  '不在下注范围':'ไม่อยู่ในขอบเขตการวางเดิมพัน',
  '当前无法参与竞猜,请联系管理员':'ขณะนี้ยังไม่สามารถเข้าร่วมทายผลได้ กรุณาติดต่อผู้ดูแลระบบ',
  '请重新输入':'กรุณากรอกอีกครั้ง',
  '铸币数量太少':'จำนวนมิ้นท์น้อยเกินไป',
  '不足':'ไม่เพียงพอ',
  '之间的金额':'จำนวนระหว่าง',
  '不在投资范围':'ไม่อยู่ในขอบเขตของการลงทุน',
  '国库共识待发行量':'ฉันทามติคงคลังที่จะหมุนเวียน',
  '强制卖出':'บังคับขาย',
  '哈希竞猜中奖':'ทายผลแฮชชนะ',
  '用户主动撤单':'ผู้ใช้ถอนรายการอย่างแข็งขัน',
  '投资USDT':'ลงทุนใน USDT',
  '收益':'ผลประโยชน์',
  '充值':'เติมเงิน',
  '提现':'ถอนเงินสด',
  '兑换':'แลก',
  '增减':'เพิ่มหรือลบ',
  '消费':'การบริโภค',
  '退款':'การคืนเงิน',
  '总计':'ทั้งหมด',
  '上拿':'หยิบขึ้น',
  '直推':'กดตรง',
  '间推':'การผลักดันระหว่าง',
  '新闻公告': 'ข่าวประกาศ',
  '详情': 'รายละเอียด',
  '邀请': 'คำเชิญ',
  '存入': 'การฝากเงิน',
  '资产': 'สินทรัพย์',
  '转账': 'โอนเงิน',
  '当前价格': 'ราคาปัจจุบัน',
  '立即': 'ทันที',
  '认购': 'สมัครสมาชิก',
  '复购': 'ซื้อซ้ำ',
  '消耗': 'การบริโภค',
  '认购记录': 'บันทึกการสมัครสมาชิก',
  '出局倍数': 'ออก หลาย',
  '倍': 'สองเท่า',
  '释放比例': 'อัตราส่วนการปล่อย',
  '数量': 'ปริมาณ',
  '可用': 'ใช้ได้',
  '最大': 'แม็กซ์',
  '排行榜': 'กระดานผู้นำ',
  '个人销售': 'การขายส่วนบุคคล',
  '直推销售': 'ขายตรง',
  '排名': 'การจัดอันดับ',
  '地址': 'ที่อยู่',
  '持有': 'ถือครอง',
  '业绩': 'ผลการดำเนินงาน',
  '友情链接': 'ลิงค์มิตรภาพ',
  '暂无记录': 'ไม่มีการบันทึกชั่วคราว',
  '没有更多了': 'ไม่มีอีกแล้ว',
  '我的地址': 'ที่อยู่ของฉัน',
  '级别': 'ระดับ',
  '首页': 'หน้าหลัก',
  '国库兑换': 'แลกเงินคงคลัง',
  '国库USDT记录': 'บันทึก USDT',
  '国库LBD铸造': 'โรงหล่อ LBD',
  '哈希竞猜': 'แฮช',
  '我的资产': 'ทรัพย์สินของฉัน',
  '账单记录': 'บันทึกการเรียกเก็บเงิน',
  '我的收益': 'กำไรของฉัน',
  '我的社区': 'ชุมชนของฉัน',
  '邀请好友': 'เชิญเพื่อน',
  '国库': 'คลังของรัฐ',
  '国库共识USDT总量': 'ฉันทามติคลัง USDT ทั้งหมด',
  '国库共识铸币流通量': 'เหรียญกษาปณ์หมุนเวียน',
  '累计销毁总量': 'จำนวนการทำลายสะสม',
  '铸币流通占比': 'อัตราส่วนการหมุนเวียนของเหรียญ',
  'LBD待发行量': 'LBD ที่จะออก',
  'LBD流通总量': 'การไหลเวียนของ LBD ทั้งหมด',
  'LBD销毁总量': 'LBD ทำลายทั้งหมด',
  '铸币': 'เหรียญกษาปณ์',
  '铸币记录': 'บันทึกมิ้นท์',
  '请输入': 'กรุณากรอก',
  '交易': 'การซื้อขาย',
  '币价': 'ราคาเหรียญ',
  '金额': 'จำนวนเงิน',
  '实际获得': 'การได้รับจริง',
  '回流国库': 'ส่งคืนคลัง',
  '卖出': 'ขาย',
  '国库USDT剩余总量': 'เงินคงคลัง USDT ส่วนที่เหลือทั้งหมด',
  '查看详情': 'ดูรายละเอียด',
  '国库LBD剩余总量': 'ยอดคงเหลือ LBD คงคลัง',
  '国库U池余额': 'ยอดเงินคงคลัง U Pool',
  '变动明细': 'รายละเอียดการเปลี่ยนแปลง',
  '价格': 'ราคา',
  '时间': 'เวลา',
  '铸币池余额': 'ยอดคงเหลือ MINT POOL',
  '铸币明细': 'รายละเอียดของ MINT',
  '铸币数量': 'จำนวน MINT',
  '铸币价格': 'ราคา MINT',
  '铸币时间': 'MINT เวลา',
  '竞猜记录': 'บันทึกการทายผล',
  '猜小': 'เดาเล็ก',
  '猜大': 'เดาใหญ่',
  '猜单': 'ทายผล',
  '猜双': 'เดาคู่',
  '竞猜金额': 'จำนวนเงินทายผล',
  '请输入竞猜金额': 'กรุณาใส่จำนวนเงินเดิมพัน',
  '最近竞猜结果': 'ผลการทายผลล่าสุด',
  '确定': 'ระบุ',
  '取消': 'การยกเลิก',
  '我的竞猜': 'การคาดเดาของฉัน',
  '竞猜': 'ทายผล',
  '竞猜结果': 'ผลการทายผล',
  '获得': 'ได้รับ',
  '我的账户': 'บัญชีของฉัน',
  '币种': 'สกุลเงิน',
  '铸造': 'แคสติ้ง',
  '团队收益': 'รายได้ของทีม',
  '推荐收益': 'รายได้แนะนำ',
  '静态收益': 'กำไรคงที่',
  '平级收益': 'กำไรระดับ',
  '奖励明细': 'รายละเอียดรางวัล',
  '当前我的等级': 'ปัจจุบันเกรดของฉัน',
  '社区节点': 'โหนดชุมชน',
  '有效节点': 'โหนดที่มีประสิทธิภาพ',
  '社区业绩': 'ผลการดำเนินงานของชุมชน',
  '大区业绩': 'ผลการดำเนินงานระดับภูมิภาค',
  '小区业绩': 'ประสิทธิภาพของชุมชน',
  '我的套餐': 'แพ็คเกจของฉัน',
  '总认购': 'สมัครสมาชิกทั้งหมด',
  '总收益': 'รายได้รวม',
  '我的直推': 'กดตรงของฉัน',
  '序号': 'หมายเลขซีเรียล',
  '注册时间': 'เวลาลงทะเบียน',
  '长按保存图片': 'กดยาวเพื่อบันทึกภาพ',
  '我的邀请地址': 'ที่อยู่เชิญของฉัน',
  '复制地址': 'คัดลอกที่อยู่',
  '复制成功': 'คัดลอกสำเร็จ',
  '返回': 'กลับ',
  '等级': 'เกรด',
  '有效人数': 'จำนวนคนที่ถูกต้อง',
  '团队人数': 'จำนวนทีม',
  '存入数量': 'จำนวนเงินฝาก',
  '请输入数量': 'กรุณาใส่ปริมาณ',
  '钱包余额': 'ยอดเงินในกระเป๋าสตางค์',
  '全部': 'ทั้งหมด',
  '交易广播成功': 'ความสำเร็จในการออกอากาศการซื้อขาย',
  '钱包余额不足': 'ยอดเงินในกระเป๋าไม่เพียงพอ',
  '正在交易中...': 'กำลังเทรด...',
  '提取币种': 'การถอนสกุลเงิน',
  '提取地址': 'ถอนที่อยู่',
  '提取数量': 'จำนวนการสกัด',
  '账户余额': 'ยอดคงเหลือในบัญชี',
  '手续费': 'ค่าธรรมเนียมการจัดการ',
  '提交': 'ส่ง',
  '转账币种': 'สกุลเงินโอน',
  '转账地址': 'ที่อยู่การโอนเงิน',
  '请输入地址': 'กรุณากรอกที่อยู่',
  '转账数量': 'จำนวนการโอนเงิน',
  '静态释放': 'ปล่อยแบบคงที่',
  '比例': 'สัดส่วน',
  '动态加速释放': 'การเร่งความเร็วแบบไดนามิก',
  '出局倍率': 'อัตราการออก',
  '撤单': 'ถอนคำสั่ง',
  '提示': 'เคล็ดลับ',
  '激活账号': 'เปิดใช้งานหมายเลขบัญชี',
  '请输入邀请码进行授权激活': 'โปรดป้อนรหัสเชิญเพื่อเปิดใช้งานการอนุญาต',
  '请输入邀请码': 'กรุณากรอกรหัสเชิญ',
  '注册': 'ลงทะเบียน',
  '获取地址错误': 'ได้รับที่อยู่ผิดพลาด',
  '认购中...': 'ในการสมัครสมาชิก...',
  '复购中...': 'ในการซื้อซ้ำ...',
  '卖出中...': 'ในการขาย...',
  '铸币中...': 'ใน MINT...',
  '竞猜下注中...': 'ในการเดิมพันทายผล...',
  '加载中...': 'กำลังโหลด...',
  '提现中...': 'ถอนเงินสด...',
  '撤单中...': 'ในคำสั่งถอน...',
  '转换中...': 'ในการแปลง...',
  '转账中...': 'ในการโอนเงิน...',
  '确定要认购吗?': 'แน่ใจนะว่าต้องการสมัคร?',
  '确定要复购吗?': 'แน่ใจนะว่าต้องการซื้อซ้ำ?',
  '确定要卖出吗?': 'แน่ใจนะว่าต้องการขาย?',
  '确定要铸币吗?': 'แน่ใจนะว่าอยากมิ้นต์?',
  '确定要竞猜吗?': 'แน่ใจนะว่าจะแข่งทายผล?',
  '确定要提现吗?': 'แน่ใจนะว่าต้องการถอนเงิน',
  '确定要转换吗?': 'แน่ใจนะว่าต้องการแปลง?',
  '确定要转账吗?': 'แน่ใจนะว่าต้องการโอนเงิน?',
  '请选择货币': 'กรุณาเลือกสกุลเงิน',
  '未回本撤出合约，只返回55%USDT，': 'หากไม่มีการถอนตัวเงินกลับสัญญาจะคืนเพียง 55% USDT,',
  '确定撤出吗?': 'แน่ใจการถอนตัว?',
  '销毁': 'การทำลาย',
  '销毁记录': 'บันทึกการทำลาย',
  '类型': 'ประเภท',
  '未注册': 'ยังไม่ได้ลงทะเบียน',
  '账号已被冻结！': 'หมายเลขบัญชีถูกระงับ!',
  'Token异常，登入失敗': 'Token ผิดปกติเข้าสู่ระบบล้มเหลว',
  '登录成功': 'เข้าสู่ระบบสำเร็จ',
  '参数错误': 'พารามิเตอร์ผิดพลาด',
  '请勿重复提交': 'ไม่ยื่นซ้ำซ้อน',
  '钱包地址已存在': 'ที่อยู่กระเป๋าสตางค์มีอยู่แล้ว',
  '推荐人不存在': 'ไม่มีผู้อ้างอิง',
  '注册成功': 'ลงทะเบียนสำเร็จ',
  '注册失败': 'ลงทะเบียนไม่สำเร็จ',
  '注册异常': 'ความผิดปกติในการลงทะเบียน',
  '找不到LBD数据': 'ไม่พบข้อมูล LBD',
  '获取成功': 'ประสบความสำเร็จ',
  '获取饼图数据异常': 'รับความผิดปกติของข้อมูลแผนภูมิวงกลม',
  '获取公开铸币信息列表异常': 'การเข้าถึงรายการข้อมูลมิ้นต์สาธารณะผิดปกติ',
  '用户信息异常': 'ข้อมูลผู้ใช้งานผิดปกติ',
  '钱包信息错误': 'ข้อมูลกระเป๋าสตางค์ผิดพลาด',
  '通证参数不存在': 'ไม่มีพารามิเตอร์บัตรผ่าน',
  '获取兑换相关信息异常': 'การได้มาซึ่งข้อมูลที่เกี่ยวข้องกับการแลกเปลี่ยนผิดปกติ',
  '获取K线数据异常': 'รับความผิดปกติของข้อมูล K-Line',
  '获取排行榜数据异常': 'ได้รับความผิดปกติของข้อมูลผู้นำ',
  '查询成功': 'สอบถามสำเร็จ',
  '查询个人钱包异常': 'สอบถามความผิดปกติของกระเป๋าเงินส่วนบุคคล',
  '最低100，且100的倍数起投': 'ขั้นต่ำ 100 และทวีคูณของ 100',
  '区块签名错误': 'ลายเซ็นบล็อกผิดพลาด',
  '区块地址错误': 'ข้อผิดพลาดของที่อยู่บล็อก',
  '找不到投资设置': 'ไม่พบการตั้งค่าการลงทุน',
  '当前无法进行投资,请联系管理员': 'ไม่สามารถลงทุนได้ในขณะนี้ โปรดติดต่อผู้ดูแลระบบ',
  '用户钱包异常': 'ความผิดปกติของกระเป๋าเงินของผู้ใช้',
  '投资异常': 'การลงทุนที่ผิดปกติ',
  '获取投资选项异常': 'การเข้าถึงตัวเลือกการลงทุนผิดปกติ',
  '文章不存在': 'ไม่มีบทความ',
  '查询文章异常': 'สอบถามความผิดปกติของบทความ',
  '兑换计算结果异常': 'ผลการคำนวณแลกเงินผิดปกติ',
  '交易数量错误': 'จำนวนการซื้อขายผิดพลาด',
  '暂时无法进行交易,请联系管理员': 'ไม่สามารถทำธุรกรรมได้ชั่วคราว โปรดติดต่อผู้ดูแลระบบ',
  '交易数量太少': 'มีปริมาณการซื้อขายน้อยเกินไป',
  '交易成功': 'ความสำเร็จในการเทรด',
  '交易异常': 'ความผิดปกติในการซื้อขาย',
  '金额错误': 'จำนวนเงินผิดพลาด',
  '铸币成功': 'ความสำเร็จของ MINT',
  '铸币异常': 'ความผิดปกติของเหรียญ',
  '查询幻灯片异常': 'สอบถามความผิดปกติของสไลด์',
  '查询公告异常': 'สอบถามความผิดปกติของประกาศ',
  '请选择玩法': 'โปรดเลือกวิธีการเล่น',
  '竞猜设置异常': 'ความผิดปกติของการตั้งค่าคำทาย',
  '当前没有可参与的竞猜': 'ขณะนี้ยังไม่มีการคาดคะเนให้เข้าร่วม',
  '当前竞猜已停止下注': 'การเดิมพันปัจจุบันได้หยุดลงแล้ว',
  '下注成功': 'เดิมพันสำเร็จ',
  '竞猜下注异常': 'ความผิดปกติของการเดิมพันทายผล',
  '获取用户竞猜订单列表异常': 'ทำให้ผู้ใช้เดาความผิดปกติของรายการสั่งซื้อ',
  '获取用户竞猜列表异常': 'รับความผิดปกติของรายชื่อทายผลผู้ใช้',
  '查询新闻异常': 'สอบถามความผิดปกติของข่าว',
  '新闻不存在': 'ข่าวไม่มีอยู่',
  '查询单条新闻异常': 'สอบถามความผิดปกติของข่าวเดี่ยว',
  '获取我的社区页面数据异常': 'รับความผิดปกติของข้อมูลหน้าชุมชนของฉัน',
  '获取我的社区列表异常': 'รับรายชื่อชุมชนของฉัน ความผิดปกติ',
  '查询用户信息异常': 'สอบถามความผิดปกติของข้อมูลผู้ใช้',
  '获取账单异常': 'รับความผิดปกติในการเรียกเก็บเงิน',
  '查询奖金来源异常': 'สอบถามที่มาของเงินรางวัลผิดปกติ',
  '区块链参数异常': 'พารามิเตอร์ blockchain ผิดปกติ',
  '查询可转换货币异常': 'สอบถามความผิดปกติของสกุลเงินแปลงสภาพ',
  '查询可提现货币异常': 'สอบถามความผิดปกติของเงินที่ถอนได้',
  '用户获取投资列表异常': 'ผู้ใช้มีรายการการลงทุนที่ผิดปกติ',
  '投资不存在': 'การลงทุนไม่มีอยู่',
  '投资已出局': 'การลงทุนออกไป',
  '投资已撤单': 'ถอนเงินลงทุน',
  '投资状态异常': 'สถานะการลงทุนผิดปกติ',
  '当前投资无法撤单': 'การลงทุนปัจจุบันไม่สามารถถอนได้',
  '撤单成功': 'การถอนเงินสำเร็จแล้ว',
  '撤单异常': 'การถอนใบสั่งผิดปกติ',
  '获取我的资产页面数据异常': 'รับข้อมูลหน้าสินทรัพย์ของฉันผิดปกติ',
  '转换': 'การแปลง',
  '转换币种': 'การแปลงสกุลเงิน',
  '转换数量': 'จำนวนการแปลง',
  '确实': 'จริง ๆ',
  '转': 'หมุนรอบ',
  '系统繁忙,请稍后再试': 'ระบบไม่ว่างโปรดลองอีกครั้งในภายหลัง',
  '货币不存在': 'สกุลเงินไม่มีอยู่',
  '转款人钱包错误': 'เกิดข้อผิดพลาดกับกระเป๋าสตางค์ของผู้โอน',
  '收款人不存在': 'ไม่มีผู้รับเงิน',
  '收款人钱包错误': 'กระเป๋าสตางค์ผู้รับเงินผิดพลาด',
  '该货币只能转给团队人员': 'สกุลเงินนี้สามารถโอนไปยังบุคลากรในทีมเท่านั้น',
  '转账成功': 'โอนเงินสำเร็จ',
  '转账异常': 'ความผิดปกติในการโอนเงิน',
  '查询可转账货币异常': 'สอบถามความผิดปกติของเงินที่โอนได้',
  '铸币消耗USD': 'MINT ใช้ USD',
  '铸币LBD数量': 'จำนวน MINT LBD',
  '提取': 'การสกัด',
  '立即铸币': 'Mint ทันที',
  '单双走势': 'การเคลื่อนไหวเดี่ยวและคู่',
  '大小走势': 'การเคลื่อนไหวของขนาด',
  '单': 'เดี่ยว',
  '双': 'คู่',
  '大': 'ใหญ่',
  '小': 'เล็ก',
  '待开奖': 'ลุ้นรับรางวัล',
  '已开奖': 'ออกรางวัลแล้ว',
  '区块高度': 'ความสูงของบล็อก',
  '涨幅': 'กำไร',
  'USD佣金': 'ค่าคอมมิชชั่น USD',
  '首页滚动公告': 'LIBERAL DEAL เป็นองค์กรอิสระแบบกระจายอำนาจ (DAO Organization) ที่เปิดตัวโดย Coin An Eco Official มีจุดมุ่งหมายเพื่อสร้างองค์กร DAO ระหว่างประเทศที่มีอิทธิพลในระยะยาวซึ่งรวบรวมพันธมิตรที่มีความรู้ความเข้าใจในละติจูดสูงเพื่อเพิ่มพลังในการสร้างระบบนิเวศ blockchain ชั้นนำของโลกด้วยอิทธิพลของชุมชน โทเค็น Liberal deal (LBD) ออกทั้งหมด 3100w ถึงภาวะเงินฝืด 310,000 และมูลค่าเหรียญหมุนเวียนของ LBD ยึดราคา BNB ผ่านข้อตกลงการกระจายอำนาจการคลัง การซื้อขาย Pancake ออนไลน์หลังจากสะสมยอดการเข้าชม 50,000 ที่อยู่จะหมุนเวียนในแอปพลิเคชัน LBD Eco ที่สร้างขึ้น',
  '玩法规则': 'กฎการเล่น',
  '为': 'สำหรับ',
  '玩法规则内容': 'ตัวเลขหลักสุดท้ายของมูลค่าแฮชของบล็อก Coin An เดิมพันด้วย USDT หากตัวเลขสุดท้ายคือตัวอักษรที่กดไปข้างหน้าจนกว่าจะพบตัวเลขให้ใช้ตัวเลข 0 ~ 9 เป็นผลการออกรางวัลทุกครั้ง 3 นาทีจะมีการออกรางวัลโดยอัตโนมัติทุกงวดนับถอยหลัง 30 วินาทีห้ามวางเดิมพันการเดาคืน 1.94 เท่าของ USD',
  '社区收益': 'ประโยชน์ชุมชน',
  '提取记录': 'บันทึกการสกัด',
  '提现金额': 'จำนวนเงินถอน',
  '交易哈希': 'แฮชแลกเปลี่ยน',
  'DG投入':'ปัจจัยการผลิต DG',
  'DG投入记录':'บันทึกการป้อนข้อมูล DG',
  '请输入金额':'กรุณาใส่จำนวนเงิน'
}