/*
不需要安装依赖,只需要rsa文件夹中的文件
*/
const PUBLICKEY = `
-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDTJ2UVZRwsw2Vhdm1puhlE5Uy8
KMKTtBnk/Bte+80wk7fwJqCzmKeEi92A3eO/EcpRW2f0F2QvEI7RI4i1tlq8p20F
dThOu68NKzR5LMPQC4dI+iefotq9G9hFmubjyZG0NLRxuoQ3YuK95U35wLDvKjvW
5FqBWflpZPImih8BMwIDAQAB
-----END PUBLIC KEY-----`

const PRIVATEKEY = ``

const { JSEncrypt } = require("./jsencrypt-rsa/jsencrypt")

//获取RSA加密密文
function getRsaCode(value) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLICKEY);
    let rsaCode = encrypt.encryptLong2(value);
    return rsaCode;
}

//获取RSA解密原数据
function getRawData(value) {
    const encrypt = new JSEncrypt();
    encrypt.setPrivateKey(PRIVATEKEY);
    let rawData = encrypt.decryptLong2(value);
    return rawData;
}

export default {
    getRsaCode,
    getRawData
};