module.exports = {
  '竞猜下注统计到指定用户':'竞猜下注统计到指定用户',
  '哈希竞猜退款':'哈希竞猜退款',
  '第':'第',
  '期':'期',
  '未中奖':'未中奖',
  '已中奖':'已中奖',
  '已撤单':'已撤单',
  '撤单申请已提交':'撤单申请已提交',
  '投资已撤单或正在撤单':'投资已撤单或正在撤单',
  '认购成功':'认购成功',
  '复购成功':'复购成功',
  '不在下注范围':'不在下注范围',
  '当前无法参与竞猜,请联系管理员':'当前无法参与竞猜,请联系管理员',
  '请重新输入':'请重新输入',
  '铸币数量太少':'铸币数量太少',
  '不足':'不足',
  '之间的金额':'之间的金额',
  '不在投资范围':'不在投资范围',
  '国库共识待发行量':'国库共识待发行量',
  '强制卖出':'强制卖出',
  '哈希竞猜中奖':'哈希竞猜中奖',
  '用户主动撤单':'用户主动撤单',
  '投资USDT':'投资USDT',
  '收益':'收益',
  '充值':'充值',
  '提现':'提现',
  '兑换':'兑换',
  '增减':'增减',
  '消费':'消费',
  '退款':'退款',
  '总计':'总计',
  '上拿':'上拿',
  '直推':'直推',
  '间推':'间推',
  '新闻公告':'新闻公告',
  '详情':'详情',
  '邀请':'邀请',
  '存入':'存入',
  '资产':'资产',
  '转账':'转账',
  '当前价格':'当前价格',
  '立即':'立即',
  '认购':'认购',
  '复购':'复购',
  '消耗':'消耗',
  '认购记录':'认购记录',
  '出局倍数':'出局倍数',
  '倍':'倍',
  '释放比例':'释放比例',
  '数量':'数量',
  '可用':'可用',
  '最大':'最大',
  '排行榜':'排行榜',
  '个人销售':'个人销售',
  '直推销售':'直推销售',
  '排名':'排名',
  '地址':'地址',
  '持有':'持有',
  '业绩':'业绩',
  '友情链接':'友情链接',
  '暂无记录':'暂无记录',
  '没有更多了':'没有更多了',
  '我的地址':'我的地址',
  '级别':'级别',
  '首页':'首页',
  '国库兑换':'国库兑换',
  '国库USDT记录':'国库USDT记录',
  '国库LBD铸造':'国库LBD铸造',
  '哈希竞猜':'哈希竞猜',
  '我的资产':'我的资产',
  '账单记录':'账单记录',
  '我的收益':'我的收益',
  '我的社区':'我的社区',
  '邀请好友':'邀请好友',
  '国库':'国库',
  '国库共识USDT总量':'国库共识USDT总量',
  '国库共识铸币流通量':'国库共识铸币流通量',
  '累计销毁总量':'累计销毁总量',
  '铸币流通占比':'铸币流通占比',
  'LBD待发行量':'LBD待发行量',
  'LBD流通总量':'LBD流通总量',
  'LBD销毁总量':'LBD销毁总量',
  '铸币':'铸币',
  '铸币记录':'铸币记录',
  '请输入':'请输入',
  '交易':'交易',
  '币价':'币价',
  '金额':'金额',
  '实际获得':'实际获得',
  '回流国库':'回流国库',
  '卖出':'卖出',
  '国库USDT剩余总量':'国库USDT剩余总量',
  '查看详情':'查看详情',
  '国库LBD剩余总量':'国库LBD剩余总量',
  '国库U池余额':'国库U池余额',
  '变动明细':'变动明细',
  '价格':'价格',
  '时间':'时间',
  '铸币池余额':'铸币池余额',
  '铸币明细':'铸币明细',
  '铸币数量':'铸币数量',
  '铸币价格':'铸币价格',
  '铸币时间':'铸币时间',
  '竞猜记录':'竞猜记录',
  '猜小':'猜小',
  '猜大':'猜大',
  '猜单':'猜单',
  '猜双':'猜双',
  '竞猜金额':'竞猜金额',
  '请输入竞猜金额':'请输入竞猜金额',
  '最近竞猜结果':'最近竞猜结果',
  '确定':'确定',
  '取消':'取消',
  '我的竞猜':'我的竞猜',
  '竞猜':'竞猜',
  '竞猜结果':'竞猜结果',
  '获得':'获得',
  '我的账户':'我的账户',
  '币种':'币种',
  '铸造':'铸造',
  '团队收益':'团队收益',
  '推荐收益':'推荐收益',
  '静态收益':'静态收益',
  '平级收益':'平级收益',
  '奖励明细':'奖励明细',
  '当前我的等级':'当前我的等级',
  '社区节点':'社区节点',
  '有效节点':'有效节点',
  '社区业绩':'社区业绩',
  '大区业绩':'大区业绩',
  '小区业绩':'小区业绩',
  '我的套餐':'我的套餐',
  '总认购':'总认购',
  '总收益':'总收益',
  '我的直推':'我的直推',
  '序号':'序号',
  '注册时间':'注册时间',
  '长按保存图片':'长按保存图片',
  '我的邀请地址':'我的邀请地址',
  '复制地址':'复制地址',
  '复制成功':'复制成功',
  '返回':'返回',
  '等级':'等级',
  '有效人数':'有效人数',
  '团队人数':'团队人数',
  '存入数量':'存入数量',
  '请输入数量':'请输入数量',
  '钱包余额':'钱包余额',
  '全部':'全部',
  '交易广播成功':'交易广播成功',
  '钱包余额不足':'钱包余额不足',
  '正在交易中...':'正在交易中...',
  '提取币种':'提取币种',
  '提取地址':'提取地址',
  '提取数量':'提取数量',
  '账户余额':'账户余额',
  '手续费':'手续费',
  '提交':'提交',
  '转账币种':'转账币种',
  '转账地址':'转账地址',
  '请输入地址':'请输入地址',
  '转账数量':'转账数量',
  '静态释放':'静态释放',
  '比例':'比例',
  '动态加速释放':'动态加速释放',
  '出局倍率':'出局倍率',
  '撤单':'撤单',
  '提示':'提示',
  '激活账号':'激活账号',
  '请输入邀请码进行授权激活':'请输入邀请码进行授权激活',
  '请输入邀请码':'请输入邀请码',
  '注册':'注册',
  '获取地址错误':'获取地址错误',
  '认购中...':'认购中...',
  '复购中...':'复购中...',
  '卖出中...':'卖出中...',
  '铸币中...':'铸币中...',
  '竞猜下注中...':'竞猜下注中...',
  '加载中...':'加载中...',
  '提现中...':'提现中...',
  '撤单中...':'撤单中...',
  '转换中...':'转换中...',
  '转账中...':'转账中...',
  '确定要认购吗?':'确定要认购吗?',
  '确定要复购吗?':'确定要复购吗?',
  '确定要卖出吗?':'确定要卖出吗?',
  '确定要铸币吗?':'确定要铸币吗?',
  '确定要竞猜吗?':'确定要竞猜吗?',
  '确定要提现吗?':'确定要提现吗?',
  '确定要转换吗?':'确定要转换吗?',
  '确定要转账吗?':'确定要转账吗?',
  '请选择货币':'请选择货币',
  '未回本撤出合约，只返回55%USDT，':'未回本撤出合约，只返回55%USDT，',
  '确定撤出吗?':'确定撤出吗?',
  '销毁':'销毁',
  '销毁记录':'销毁记录',
  '类型': '类型',
  '未注册': '未注册',
  '账号已被冻结！': '账号已被冻结！',
  'Token异常，登入失敗': 'Token异常，登入失敗',
  '登录成功': '登录成功',
  '参数错误': '参数错误',
  '请勿重复提交': '请勿重复提交',
  '钱包地址已存在': '钱包地址已存在',
  '推荐人不存在': '推荐人不存在',
  '注册成功': '注册成功',
  '注册失败': '注册失败',
  '注册异常': '注册异常',
  '找不到LBD数据': '找不到LBD数据',
  '获取成功': '获取成功',
  '获取饼图数据异常': '获取饼图数据异常',
  '获取公开铸币信息列表异常': '获取公开铸币信息列表异常',
  '用户信息异常': '用户信息异常',
  '钱包信息错误': '钱包信息错误',
  '通证参数不存在': '通证参数不存在',
  '获取兑换相关信息异常': '获取兑换相关信息异常',
  '获取K线数据异常': '获取K线数据异常',
  '获取排行榜数据异常': '获取排行榜数据异常',
  '查询成功': '查询成功',
  '查询个人钱包异常': '查询个人钱包异常',
  '最低100，且100的倍数起投': '最低100，且100的倍数起投',
  '区块签名错误': '区块签名错误',
  '区块地址错误': '区块地址错误',
  '找不到投资设置': '找不到投资设置',
  '当前无法进行投资,请联系管理员': '当前无法进行投资,请联系管理员',
  '用户钱包异常': '用户钱包异常',
  '投资异常': '投资异常',
  '获取投资选项异常': '获取投资选项异常',
  '文章不存在': '文章不存在',
  '查询文章异常': '查询文章异常',
  '兑换计算结果异常': '兑换计算结果异常',
  '交易数量错误': '交易数量错误',
  '暂时无法进行交易,请联系管理员': '暂时无法进行交易,请联系管理员',
  '交易数量太少': "交易数量太少",
  '交易成功': '交易成功',
  '交易异常': '交易异常',
  '金额错误': '金额错误',
  '铸币成功': '铸币成功',
  '铸币异常': '铸币异常',
  '查询幻灯片异常': '查询幻灯片异常',
  '查询公告异常': '查询公告异常',
  '请选择玩法': '请选择玩法',
  '竞猜设置异常': '竞猜设置异常',
  '当前没有可参与的竞猜': '当前没有可参与的竞猜',
  '当前竞猜已停止下注': '当前竞猜已停止下注',
  '下注成功': '下注成功',
  '竞猜下注异常': '竞猜下注异常',
  '获取用户竞猜订单列表异常': '获取用户竞猜订单列表异常',
  '获取用户竞猜列表异常': '获取用户竞猜列表异常',
  '查询新闻异常': '查询新闻异常',
  '新闻不存在': '新闻不存在',
  '查询单条新闻异常': '查询单条新闻异常',
  '获取我的社区页面数据异常': '获取我的社区页面数据异常',
  '获取我的社区列表异常': '获取我的社区列表异常',
  '查询用户信息异常': '查询用户信息异常',
  '获取账单异常': '获取账单异常',
  '查询奖金来源异常': '查询奖金来源异常',
  '区块链参数异常': '区块链参数异常',
  '查询可转换货币异常': '查询可转换货币异常',
  '查询可提现货币异常': '查询可提现货币异常',
  '用户获取投资列表异常': '用户获取投资列表异常',
  '投资不存在': '投资不存在',
  '投资已出局': '投资已出局',
  '投资已撤单': '投资已撤单',
  '投资状态异常': '投资状态异常',
  '当前投资无法撤单': '当前投资无法撤单',
  '撤单成功': '撤单成功',
  '撤单异常': '撤单异常',
  '获取我的资产页面数据异常': '获取我的资产页面数据异常',
  '转换': '转换',
  '转换币种': '转换币种',
  '转换数量': '转换数量',
  '确实': '确实',
  '转': '转',
  '系统繁忙,请稍后再试': '系统繁忙,请稍后再试',
  '货币不存在': '货币不存在',
  '转款人钱包错误': '转款人钱包错误',
  '收款人不存在': '收款人不存在',
  '收款人钱包错误': '收款人钱包错误',
  '该货币只能转给团队人员': '该货币只能转给团队人员',
  '转账成功': '转账成功',
  '转账异常': '转账异常',
  '查询可转账货币异常': '查询可转账货币异常',
  '铸币消耗USD': '铸币消耗USD',
  '铸币LBD数量': '铸币LBD数量',
  '提取': '提取',
  '立即铸币': '立即铸币',
  '单双走势': '单双走势',
  '大小走势': '大小走势',
  '单': '单',
  '双': '双',
  '大': '大',
  '小': '小',
  '待开奖': '待开奖',
  '已开奖': '已开奖',
  '区块高度': '区块高度',
  '涨幅': '涨幅',
  'USD佣金': 'USD佣金',
  '首页滚动公告': 'LIBERAL DEAL 是由币安生态官方推出的一个去中心化自治组织 (DAO组织)  旨在建设一个有长远影响力的国际DAO组织，凝聚高纬认知的伙伴，用社区的影响力，赋能全球顶级区块链生态建设。Liberal deal （LBD) 代币总发行3100w枚，极至通缩31万，LBD流通币量价值通过国库去中心化协议锚定BNB价格。累计到5万地址流量后上线Pancake进行交易，将在打造的LBD生态应用中流通。',
  '玩法规则': '玩法规则',
  '为': '为',
  '玩法规则内容': '币安区块哈希值的最后一位数字竞猜，用USDT下注，若最后一位是字母即往前推一直找到数字为止，始终以数字0~9为开奖结果，每3分钟自动开奖一期，每期倒计时30秒禁止下注，猜中返1.94倍USD。',
  '社区收益': '社区收益',
  '提取记录': '提取记录',
  '提现金额': '提现金额',
  '交易哈希': '交易哈希',
  'DG投入':'DG投入',
  'DG投入记录':'DG投入记录',
  '请输入金额':'请输入金额'
}